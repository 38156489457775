/* Global Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --primary-color: #59ab6e;
    --text-color: gray;
    --text-white: white;
}

/* Body Styles */
body {
    display: flex;
    flex-direction: column;
    overflow:auto;
}

/* Navbar Styles */
.navbar {
    background-color: white;
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding: 10px 10px;
    margin-left: 5px;
}

.navbar h1 {
    color: var(--primary-color);
    font-size: 2rem;
    text-align: center;
}

/* Card Styles */
.Card ul {
    display: flex;
    list-style: none;
    margin-right: 300px;
}

.Card ul li {
    margin-left: 150px;
}

.Card ul li a {
    color: var(--text-color);
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease; /* Smooth color transition */
}

.Card ul li a:hover {
    color: darkslategray;
}

/* About Section Styles */
.About {
    text-align: justify;
    padding: 50px;
    background-color: var(--primary-color);
    color: var(--text-white);
    height: 100vh;
}

.about-body {
    display: flex;
    align-items: center;
}

.about-content {
    width: 80%;
}

.About h2 {
    font-size: 2.25rem; /* Responsive heading size */
    margin-top: 86px;
    padding: 10px;
}

.About p {
    font-size: 1.125rem; /* Maintain readability */
    max-width: 600px;
    padding: 30px 20px 0;
}

/* Image Container Styles */
.image-container {
    display: flex;
    justify-content: flex-end;
}

.image-container img {
    object-fit: cover;
    max-width: 100%;
    height: auto;
}

.row {
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.row img {
    width: 100%;
    height: 375px; /* Maintain aspect ratio */
    margin-top: -55px;
}

.Image-Details {
    background-color: transparent;
    color: rgb(255, 250, 250);
    position: absolute;
    margin-top: -150px;
    margin-left: 1%;
}

.Left-arrow, .Right-arrow {
    position: absolute;
    top: 60%; /* Center vertically */
    font-size: 1.875rem; /* Consistent sizing */
    border-radius: 5px;
    color: var(--primary-color);
    background-color: transparent;
    cursor: pointer;
    z-index: 1;
}

.Left-arrow {
    left: 1%;
}

.Right-arrow {
    right: 1%;
}

/* Media Queries for Responsiveness */
@media (max-width: 800px) {
    .navbar h1 {
        font-size: 1.5rem;
    }
    .Card ul {
        display: flex;
        list-style: none;
     margin-left:-50px;
      
    }
    .Card ul li {
        margin-right:-80px;
    }
    .About h2 {
        font-size: 1.75rem;
    }

    .About p {
        font-size: 1rem;
    }

    .about-body {
        flex-direction: column;
    }

    .about-content {
        width: 100%;
    }

    .row {
        margin-top: 100px;
    }

    .Left-arrow, .Right-arrow {
        font-size: 1.5rem;
        display: none; /* Show arrows if necessary */
    }

    .row img {
        max-width: 100%;
    }

    .Image-Details {
        position: relative;
        top: 10%;
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {
    .navbar h1 {
        font-size: 1.125rem;
    }
    .Card ul {
        display: flex;
        list-style: none;
     margin-left:-50px;
      
    }
    .Card ul li {
        margin-right:-130px;
    }
    .About h2 {
        font-size: 1.375rem;
    }

    .About p {
        font-size: 0.875rem;
        padding: 5px;
    }

    .about-body {
        margin-top: 10px;
        flex-direction: row;
    }

    .about-content {
        margin-top: -30%;
    }

    .row {
        margin-bottom: 100px;
    }

    .Left-arrow, .Right-arrow {
        font-size: 1.25rem;
        display: none;
        margin: 5px;
    }

    .row img {
        position: absolute;
        max-width: 15%;
        max-height: 300px;
        height: auto;
    }

    .Image-Details {
        position: relative;
        color: black;
        font-size: 0.8em;
        margin-top: 10px;
        padding-right: 50px;
    }
}
